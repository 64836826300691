import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import App from "./App";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import { myNewTheme } from "./assets/styles/theme";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


// var AUTH0_DOMAIN: string
// var AUTH0_CLIENT_ID: string 
//  if (process.env.AUTH0_DOMAIN) {
//   AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID!
//   AUTH0_DOMAIN = process.env.AUTH0_DOMAIN
//  }

root.render(
  <Auth0Provider
    domain="https://tekstedia.us.auth0.com"
    clientId="Owg4ofPICqrqcW2fe1jzFwnNPgpXR0Fu"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider resetCSS theme={myNewTheme}>
        <App />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
