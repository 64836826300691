import { useState, useEffect, useRef } from 'react';
import 'animate.css';
import { Link as RouteLink } from 'react-router-dom';
import { FaSun } from 'react-icons/fa';
import { RiMoonLine } from 'react-icons/ri';
import {MdArrowDropDown} from 'react-icons/md';

import {
  AiOutlineDownCircle,
  AiOutlineUpCircle,
  AiOutlineRight,
  AiOutlineDash,
  AiOutlineMenu,
} from 'react-icons/ai';
import {
  Button,
  IconButton,
  VStack,
  Flex,
  Grid,
  GridItem,
  Text,
  useColorMode,
  useMediaQuery,
  Image,
  Select,
  Spinner,
} from '@chakra-ui/react';
import Box from '../../components/common/Box';
import HeaderGrid from '../../components/common/HeaderGrid';
import AppName from '../../components/common/AppName';
import { navRoutes } from '../../routes/routes';
import PrimaryAuthBtn from '../../components/common/primaryAuthBtn/PrimaryAuthBtn';
import NavOpen from './NavOpen';
import NavLong from './NavLong';
import SigninBtn from '../../components/common/SigninBtn/SignInBtn';
import SignoutBtn from '../../components/common/SignoutBtn/SignoutBtn';
import { useAuth0 } from '@auth0/auth0-react';
// import axios from 'axios';
import SignupBtn from '../../components/common/SignupBtn/SignupBtn';

interface PopItemProps {
  name: string;
}

function PopItem({ name }: PopItemProps) {
  return (
    <Box
      _hover={{
        cursor: 'pointer',
        borderBottom: '1px solid teal',
      }}
      borderRadius="0"
      borderBottom="1px solid #e5e5e5"
      // width="200px"
      // as={RouteLink}
      // to="/Login"
    >
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap={4}
      >
        <GridItem colSpan={4}>
          <Text>{name}</Text>
        </GridItem>
        <GridItem
          colStart={5}
          colEnd={6}
          _hover={{
            transform: 'translateX(0.5rem)',
          }}
          color="primary"
        >
          <AiOutlineRight />
        </GridItem>
      </Grid>
    </Box>
  );
}
/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: { target: any }) {
      if (ref.current && !ref.current.contains(event.target)) {
        alert('You clicked outside of me!');
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
const LandingHeader: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { logout } = useAuth0();

  const [isMobileDevices] = useMediaQuery('(max-width: 480px)');
  
  const [daUser, setDaUser] = useState<any>();

  // useEffect(() => {
    
  //   setDaUser(user)
  //   axios.post('localhost:3000/user', daUser)
  // .then(response => {
  //   // Handle the response
  //   console.log('User data posted successfully!');
  // })
  // .catch(error => {
  //   // Handle the error
  //   console.error('Error posting user data:', error);
  // });
  //   // Persist user data to Local storage
  //   localStorage.setItem('user', JSON.stringify(daUser));
  // }, [daUser]);
  const toUserDash = () => {
    const encodedUser = encodeURIComponent(JSON.stringify(user));
    window.location.href = `http://localhost:3001?user=${encodedUser}`;
  }
  const { colorMode, toggleColorMode } = useColorMode();
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(!hovered);
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const AppNameVariants = {
    visible: { opacity: 1, scale: 4, transition: { duration: 1 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const [navOpen, setNavOpen] = useState(false);
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <Box
      w="100vw"
      as="header"
      borderRadius={0}
      position="fixed"
      top="0"
      borderBottom="1px solid gainsboro"
      zIndex="overlay"
    >
      <Box
        position="fixed"
        onClick={() => setNavOpen(false)}
        h="100%"
        top={0}
        left={0}
        bottom={0}
        w="100vw"
        borderRadius={0}
        display={navOpen ? 'block' : 'none'}
        background="rgba(0,0,0,0.5)"
      />
      {/* <GridItem rowSpan={3} colSpan={1} /> */}
      <Flex
        boxSizing="border-box"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        pb={0}
        w="95vw"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
        >
          {!isLargerThan768 && (
            <Box
              onClick={() => setNavOpen(true)}
              _hover={{ cursor: 'pointer' }}
            >
              <AiOutlineMenu />
            </Box>
          )}
          {navOpen && <NavOpen />}
          <Box
            ml="1rem"
            className="animate__animated animate__backInDown"
            as="h1"
            fontSize={{
              base: '.5rem',
              md: '.8rem',
              lg: '.9rem',
              xl: '1rem',
            }}
          >
            <AppName txtDecoration="none" />
          </Box>
          {isLargerThan768 && <NavLong />}
        </Flex>
        {!isAuthenticated && (
          <Flex gap="4">
            {!isMobileDevices ? (<><SigninBtn /><Box h="0.4rem" /><SignupBtn /></>) : <SignupBtn />}
          </Flex>
        )}
        {isLoading && (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="md"
          />
        )}

        {isAuthenticated && (
          <Flex
            boxSizing="border-box"
            gap="1.5rem"
            justify="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box
              fontSize="13px"
              mb=".4rem"
              _hover={{ cursor: 'pointer' }}
              color="primary"
              onClick={() => handleHover()}
            >
              <Flex
                boxSizing="border-box"
                gap="1.5rem"
                justifyContent="center"
                textAlign="center"
                alignItems="center"
                flexWrap="wrap"
                border="2px solid gainsboro"
                p=".7px"
                borderRadius="50%"
              >
                <Image
                  h="40px"
                  borderRadius="50%"
                  src={user?.picture}
                  alt={user?.name}
                />
              </Flex>
            </Box>
            <Box
              // onMouseLeave={handleHover}
              fontSize="13px"
              ref={wrapperRef}
              sx={{
                display: hovered ? 'block' : 'none',
                width: 'auto',
                padding: '18px',
                border: '1px solid #bdbdbd',
                borderRadius: '4px',
                height: 'fit-content',
                marginTop: 0,
                position: 'absolute',
                zIndex: 99,
                top: '3.7rem',
                right: '4rem',
                transition: '.2s ease-in-out',
              }}
            >
              <VStack
                // width="90%"
                gap="20px"
                textAlign="left"
                alignItems="left"
                justifyContent="left"
              >
                <VStack
                  textAlign="left"
                  alignItems="left"
                  justifyContent="left"
                  // width="200px"
                  gap="1rem"
                >
                  <Flex
                    justifyContent="space-between"
                    gap="9px"
                    alignItems="center"
                  >
                    <Image
                      h="40px"
                      borderRadius="50%"
                      src={user?.picture}
                      alt={user?.name}
                    />
                    <VStack
                      gap="0"
                      alignItems="left"
                    >
                      <Text
                        m="0"
                        fontWeight={600}
                      >
                        {user?.name}
                      </Text>
                      <Text
                        color="#5f6368"
                        m="0"
                        fontWeight={500}
                        fontSize="12px"
                      >
                        {user?.email}
                      </Text>
                    </VStack>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    gap="9px"
                    alignItems="center"
                  >
                    <Text>Theme</Text>
                    <Flex
                      justifyContent="space-between"
                      gap="9px"
                      alignItems="center"
                    >
                      <IconButton
                        aria-label="Toggle dark mode"
                        icon={
                          colorMode === 'dark' ? (
                            <FaSun />
                          ) : (
                            <RiMoonLine />
                          )
                        }
                        onClick={toggleColorMode}
                      />
                      <Text>
                        {colorMode === 'dark'
                          ? 'Dark(beta)'
                          : 'Light'}
                      </Text>
                    </Flex>
                    <Select icon={<MdArrowDropDown />}>
                      <option
                        value="Light"
                        defaultChecked
                        onSelect={toggleColorMode}
                      >
                        <Flex>
                          <IconButton
                            aria-label="Toggle color mode"
                            icon={<FaSun />}
                          />
                          Light
                        </Flex>
                      </option>
                      <option
                        value="Dark-beta"
                        onClick={toggleColorMode}
                      >
                        <Flex>
                          <IconButton
                            aria-label="Toggle color mode"
                            icon={<RiMoonLine />}
                          />
                          Dark (beta)
                        </Flex>
                      </option>
                    </Select>
                  </Flex>
                  <hr />
                  <Box
                    onClick={toUserDash}
                  >
                    <PopItem name="Your profile" />
                  </Box>
                  <Box
                    onClick={() =>
                      logout({
                        logoutParams: {
                          returnTo: window.location.origin,
                        },
                      })
                    }
                  >
                    <PopItem name="Log out" />
                  </Box>
                </VStack>
              </VStack>
            </Box>
          </Flex>
        )}
        {/* {navRoutes.map((item) => (
            <Box
              fontSize="13px"
              _hover={{
                cursor: 'pointer',
                textDecoration: 'underline',
                textUnderlineOffset: '1rem',
                textDecorationColor: 'primary',
              }}
            >
              {(item.name as string).indexOf('Jobs') >= 0 ? (
                <Button fontSize="13px" variant="primary">{item.name}</Button>
              ) : (
                <Text>{item.name}</Text>
              )}
            </Box>
          ))} */}
      </Flex>
    </Box>
  );
};

export default LandingHeader;
