import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { internshipDataType } from "../../packages/InternshipListing/src/utils/types";
import { makeExtendedInternshipData } from "../../packages/InternshipListing/src/functions/makeExtendedInternshipData";




// Define the initial state using that type
const initialState: internshipDataType[] = makeExtendedInternshipData()

export const internshipDataSlice = createSlice({
  name: "internshipData",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateInternshipData: (state, action: PayloadAction<internshipDataType>) => ({
      ...state,
      ...action.payload,
    }),
    internshipClicked: (state, action: PayloadAction<string>) => {
      state.forEach((internship) => {
        if (internship.id === action.payload) {
          internship.clicked = true;
        } else {
          internship.clicked = false;
        }
      });
    },
  },
});

export const { updateInternshipData, internshipClicked } = internshipDataSlice.actions;

export default internshipDataSlice.reducer;
