import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobDataType } from "../../packages/JobListing/src/utils/types";
import { makeExtendedJobData } from "../../packages/JobListing/src/functions/makeExtendedJobData";

// Define the state shape for job data
interface JobDataState {
  data: JobDataType[];  // Array of job data
  loading: boolean;     // Loading state flag
  error: string | null; // Error message
}

// Define the initial state
const initialState: JobDataState = {
  data: [],
  loading: true,
  error: null,
};

// Create the jobData slice
export const jobDataSlice = createSlice({
  name: "jobData",
  initialState,
  reducers: {
    // Reducer for starting the job data fetch
    fetchJobDataStart: (state) => {
      state.loading = true;
      state.error = null;
    },

    // Reducer for successful job data fetch
    fetchJobDataSuccess: (state, action: PayloadAction<JobDataType[]>) => {
      state.data = action.payload;
      state.loading = false;
    },

    // Reducer for failed job data fetch
    fetchJobDataFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },

    // Reducer for marking a job as clicked
    jobClicked: (state, action: PayloadAction<string>) => {
      state.data.forEach((job) => {
        if (job.id === action.payload) {
          job.clicked = true;
        } else {
          job.clicked = false;
        }
      });
    },
  },
});

// Extract the action creators
export const {
  fetchJobDataStart,
  fetchJobDataSuccess,
  fetchJobDataFailure,
  jobClicked,
} = jobDataSlice.actions;

// Thunk action creator for fetching job data
export const fetchJobData = () => {
  return async (dispatch: any) => {
    try {
      dispatch(fetchJobDataStart());
      const jobData = await makeExtendedJobData();
      dispatch(fetchJobDataSuccess(jobData));
    } catch (error: any) {
      dispatch(fetchJobDataFailure(error.message));
    }
  };
};

// Export the reducer function
export default jobDataSlice.reducer;
