import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const SignupBtn = () => {
  const navigate = useNavigate();
  const toLogin = () => {
    navigate("/login")
  }
  return (
    <Button
      onClick={() => toLogin()}
      variant="primary"
      w="fit-content"
      fontSize="13px"
      fontWeight="400"
    >
      Sign in
    </Button>
  );
};

export default SignupBtn;
