import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as RouteLink, useLocation } from 'react-router-dom';
import AppName from '../../components/common/AppName';
import Box from '../../components/common/Box';
import { navRoutes } from '../../routes/routes';

type ItemType = {
  text: string;
  route: string;
};
const Item = ({ text, route }: ItemType) => {
  var active = false;
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const RouteCapitalise = splitLocation[1].toUpperCase();
  if (RouteCapitalise === text.toUpperCase()) {
    active = true;
  }

  return (
    <Flex
      pl="1rem"
      mt=".7rem"
      background={active ? `gainsboro` : `inherit`}
      h="5ch"
      alignItems="center"
      borderRadius={0}
      _hover={{ cursor: 'pointer' }}
      as={RouteLink}
      to={route}
    >
      <Text>{text}</Text>
    </Flex>
  );
};
const NavOpen = () => {
  return (
    <Box
      className="animate__animated animate__fadeInLeft"
      height="100%"
      p="0"
      left={0}
      borderRadius={0}
      position="fixed"
      width="65%"
      top="0"
      zIndex="overlay"
    >
      <Box
        borderBottom="1px solid gainsboro"
        p="18px"
        pl="2rem"
        borderRadius="0"
      >
        <AppName />
      </Box>
      <Flex
        mt=".5rem"
        flexDir="column"
        fontSize="14px"
        fontWeight={500}
        color="#5f6368"
      >
        {navRoutes.map((deRoute, index) => {
          return (
            <Item
              key={index}
              text={deRoute.name}
              route={deRoute.path}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

export default NavOpen;
