import { volunteerDataType } from "../utils/types";
import volunteerData from "../Data/VOLUNTEER_DATA.json"

export const makeExtendedVolunteerData = (): volunteerDataType[] => {
    const extendedJobData = volunteerData.map((volunteer) => {
        const generatedId =
            'volunteer' +
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
        const clicked = true;
        return {
            ...volunteer,
            id: generatedId,
            clicked,
        };
    });
    return extendedJobData;
}