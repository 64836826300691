import './App.css';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { useColorMode, Box } from '@chakra-ui/react';
import LandingHeader from './layouts/headers/Landing.header';
import SkeletonScreen from './components/common/SkeletonScreen/SkeletonScreen';
import routes from './routes/routes';
import ScrollToTop from './ScrollToTop';

// Component to conditionally render the LandingHeader based on route
function HeaderRoutes() {
  const location = useLocation();
  const shouldRenderHeader = !['/login', '/signup', '/forgot-password'].includes(location.pathname);

  // Render LandingHeader if shouldRenderHeader is true, otherwise null
  return shouldRenderHeader ? <LandingHeader /> : null;
}

function App() {
  const { colorMode } = useColorMode();

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Box
        className="App"
        color={colorMode === 'light' ? '#5f6368' : 'white'}
      >
        {/* Render the HeaderRoutes component */}
        <HeaderRoutes />
        <Suspense fallback={<SkeletonScreen />}>
          <Routes>
            {/* Map over the routes array and render each route */}
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.element />}
              />
            ))}
          </Routes>
        </Suspense>
      </Box>
    </BrowserRouter>
  );
}

export default App;
