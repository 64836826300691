// api.ts

import axios from 'axios';
import { RootState } from '../../../../app/store';
import { JobDataType } from './types';
import endpoints from '../../../../endpoints';

export async function getJobs(): Promise<JobDataType[]> {
  try {
    const response = await axios.get<JobDataType[]>(endpoints[4].url);
    return response.data;
  } catch (error) {
    // Handle error
    console.error('Error fetching jobs:', error);
    throw error;
  }
}
