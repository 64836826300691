import { configureStore } from "@reduxjs/toolkit";

// import the search slice reducer
import searchSlice from "../features/search/search-slice";
import payoutTransactionSlice from "../features/transaction/payoutTransaction-slice";
import comissionTransactionSlice from "../features/transaction/comissionTransaction-slice";
import mainSidebarSlice from "../features/sidebar/main-slice";
import formSlice from "../features/form/form-slice";
import jobDataSlice from "../features/jobData/jobData-slice";
import internshipDataSlice from "../features/internshipData/internshipData-slice";
import volunteerDataSlice from "../features/volunteerismData/volunteerismData-slice";

// Create the store, adding the search slice to it
export const store: any = configureStore({
  reducer: {
    search: searchSlice,
    payoutTransaction: payoutTransactionSlice,
    comissionTransaction: comissionTransactionSlice,
    mainSidebar: mainSidebarSlice,
    form: formSlice,
    jobData: jobDataSlice,
    internshipData: internshipDataSlice,
    volunteerData: volunteerDataSlice,
  },
});

// Export some helper types used to improve type-checking
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
