import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { volunteerDataType } from "../../packages/VolunteerListing/src/utils/types";
import { makeExtendedVolunteerData } from "../../packages/VolunteerListing/src/functions/makeExtendedVolunteerData";




// Define the initial state using that type
const initialState: volunteerDataType[] = makeExtendedVolunteerData()

export const volunteerDataSlice = createSlice({
  name: "volunteerData",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateVolunteerData: (state, action: PayloadAction<volunteerDataType>) => ({
      ...state,
      ...action.payload,
    }),
    volunteerClicked: (state, action: PayloadAction<string>) => {
      state.forEach((volunteer) => {
        if (volunteer.id === action.payload) {
          volunteer.clicked = true;
        } else {
          volunteer.clicked = false;
        }
      });
    },
  },
});

export const { updateVolunteerData, volunteerClicked } = volunteerDataSlice.actions;

export default volunteerDataSlice.reducer;
