import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ResumeData,
  Request4PlacementData,
  ContactDetailsData,
  HigherEducationData,
  WorkExperienceData,
  SkillsData,
  CoverLetterData,
  FormState,
} from '../../interfaces/formStructure';

const initialResumeData: ResumeData = {
  selectedFile: null,
};

const initialRequest4PlacementData: Request4PlacementData = {
  schoolAuth: null,
  school: '',
  startDate: new Date('2023-09-25'),
  duration: 2,
};

const initialContactDetailsData: ContactDetailsData = {
  legalName: '',
  address: '',
  email: '',
  town: '',
  primaryPhone: '',
  additionalEmail: '',
  website: '',
};

const initialHigherEducationData: HigherEducationData = {
  school: '',
  degree: '',
  degreeStatus: 'Incomplete',
  country: '',
  major: '',
};

const initialWorkExperienceData: WorkExperienceData = {
  employerName: '',
  startDate: '',
  jobTitle: '',
  isCurrentJob: 'Yes',
  jobCountry: '',
  jobCity: '',
};

const initialSkillsData: SkillsData = {
  skills: '',
};

const initialCoverLetterData: CoverLetterData = {
  coverLetter: '',
};
const initialState: FormState = {
  request4PlacementData: initialRequest4PlacementData,
  resumeData: initialResumeData,
  contactDetailsData: initialContactDetailsData,
  higherEducationData: initialHigherEducationData,
  workExperienceData: initialWorkExperienceData,
  skillsData: initialSkillsData,
  coverLetterData: initialCoverLetterData,
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateResumeData: (
      state,
      action: PayloadAction<{ selectedFile: File | null }>,
    ) => {
      state.resumeData.selectedFile = action.payload.selectedFile;
    },
    
    updateRequest4PlacementData: (
      state,
      action: PayloadAction<Request4PlacementData>,
    ) => {
      state.request4PlacementData = action.payload;
    },
    updateContactDetailsData: (
      state,
      action: PayloadAction<ContactDetailsData>,
    ) => {
      state.contactDetailsData = action.payload;
    },
    updateHigherEducationData: (
      state,
      action: PayloadAction<HigherEducationData>,
    ) => {
      state.higherEducationData = action.payload;
    },
    updateWorkExperienceData: (
      state,
      action: PayloadAction<WorkExperienceData>,
    ) => {
      state.workExperienceData = action.payload;
    },
    updateSkillsData: (state, action: PayloadAction<SkillsData>) => {
      state.skillsData = action.payload;
    },
    updateCoverLetterData: (
      state,
      action: PayloadAction<CoverLetterData>,
    ) => {
      state.coverLetterData = action.payload;
    },
  },
});

export const {
  updateResumeData,
  updateRequest4PlacementData,
  updateContactDetailsData,
  updateHigherEducationData,
  updateWorkExperienceData,
  updateSkillsData,
  updateCoverLetterData,
} = formSlice.actions;

export default formSlice.reducer;
