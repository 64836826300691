import { mode, whiten, darken } from "@chakra-ui/theme-tools";
import { defineStyleConfig } from "@chakra-ui/react";

export const Box = defineStyleConfig({
  // The styles all Boxes have in common
  baseStyle: (props: any) => ({
    borderRadius: 'xl',
    bg: mode(whiten('#fff', 0), darken('black', 0))(props),
  }),
  // Two variants: rounded and nav
  variants: {
    base: () => ({}),

    rounded: (props: any) => ({
      padding: 8,
      borderRadius: 'xl',
      boxShadow: 'xl',
      bg: mode(darken('#fff', 0), whiten('black', 0))(props),
    }),
    activeItem: () => ({
      color: '#fff',
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(5px)',
      bgGradient:
        'linear(-273.78deg,darken 2.4%, danger 27.63%, primary 102.85%)',
    }),
    navItem: (props: any) => ({
      color: '#fff',
      // make a transparent background
      bg: mode(
        darken('primary', 0),
        whiten('rgb(18, 18, 18)', 0),
      )(props),
    }),
    nav: (props: any) => ({
      color: mode(whiten('black', 10), darken('#fff', 0))(props),
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(5px)',
      border: '1px solid rgba(255, 255, 255, 0.3)',
      bg: mode(
        darken('rgba(255, 255, 255, 0.2)', 0),
        whiten('rgb(18, 18, 18)', 0),
      )(props),
    }),
    sideNav: (props: any) => ({
      borderRadius: 'xl',
      color: mode(whiten('black', 10), darken('#fff', 0))(props),
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      bg: mode(
        darken('primary', 0),
        whiten('rgb(18, 18, 18)', 0),
      )(props),
    }),
    notice: (props: any) => ({
      borderRadius: 'md',
      padding: '1.6rem',
      color: mode(whiten('black', 10), darken('#fff', 0))(props),
      bg: mode(
        whiten('rgb(232,240,254)', 0),
        darken('rgb(18, 18, 18)', 0),
      )(props),
    }),
    footer: (props: any) => ({
      borderRadius: '0',
      boxShadow:
        'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px',
      color: mode(
        whiten('rgb(128,134,139)', 10),
        darken('#fff', 1),
      )(props),
      bg: mode(
        whiten('rgb(2488,249,250)', 0),
        darken('rgb(18, 18, 18)', 0),
      )(props),
    }),
    miniFooter: (props: any) => ({
      borderRadius: '0',
      color: mode(
        whiten('rgb(128,134,139)', 10),
        darken('#fff', 1),
      )(props),
      bg: 'transparent',
    }),
    note: (props: any) => ({
      borderRadius: 'md',
      borderLeft: '2px solid #0A1DAF',
      bg: mode(
        whiten('#F8F9FA', 0),
        darken('rgb(18, 18, 18)', 20),
      )(props),
    }),
  },
  // The default variant value
  defaultProps: {
    variant: 'base',
  },
});
