const rootEndpoint = "https://api.tekstedia.com/API/";
const endpoints = [
  {
    name: "Job Applicant Register endpoint",
    description: "Used to send user data from Job application to admin",
    method: "POST",
    url: rootEndpoint + "registerApplicant.php",
  },
  {
    name: "Send automatic email",
    description: "Used to send automatic email to applicant",
    method: "POST",
    url: rootEndpoint + "email.php",
  },
  {
    name: "Internship Applicant Register endpoint",
    description: "Used to send intern data from Intern application to admin",
    method: "POST",
    url: rootEndpoint + "registerIntern.php",
  },
  {
    name: "Volunteer Applicant Register endpoint",
    description: "Used to send volunteer data from Volunteer application to admin",
    method: "POST",
    url: rootEndpoint + "registerVolunteer.php",
  },
  {
    name: "Get all Posted Jobs",
    description: "Used to get all the posted jobs",
    method: "GET",
    url: rootEndpoint + "postedJobs.php",
  },
];

export default endpoints;
