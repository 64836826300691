import { internshipDataType } from "../utils/types";
import internshipData from "../Data/INTERNSHIP_DATA.json"

export const makeExtendedInternshipData = (): internshipDataType[] => {
    const extendedJobData = internshipData.map((internship) => {
        const generatedId =
            'internship' +
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
        const clicked = true;
        return {
            ...internship,
            id: generatedId,
            clicked,
        };
    });
    return extendedJobData;
}