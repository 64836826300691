import { extendTheme, type ThemeConfig } from "@chakra-ui/react";
import { mode, StyleFunctionProps } from "@chakra-ui/theme-tools";

import { ButtonStyles as Button } from "./components/buttonStyles";
import { BodyStyles as Styles } from "./components/bodyStyles";
import { Card } from "./components/cardStyles";
import { Box } from "./components/boxStyles";
import { Grid } from "./components/gridStyles";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
};
/* Pallete 1 */
const black = "#000000";

const lightDark = "#121318";

const white = "#ffffff";
// const whitish = "#F9FAFB";
const platinum = "#e66868";
const silver = "#bfbfbf";
// const manatee = '#96939b';
// const dodgerBlue = '#1e90ff';
const mangoTango = "#fc814a";
const myTeal = "#14b8a6";
const myPurple = "#8b5cf6";
const venetianRed = "#C90612ff";
const background = { _light: white, _dark: black };

const tekstediaColorPalatte = {
  black: "#000000",
  zaffre: "#0A1DAF",
  cornellRed: "#AD0911",
  engineeringOrange: "#C2030E",
};
// const config: ThemeConfig = {
//   initialColorMode: 'light',
//   useSystemColorMode: false,
// };

export const myNewTheme = extendTheme({
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        color: mode('#5f6368', 'white'),
        bg: mode(background._light, background._dark)(props),
      },
    }),
  },
  fonts: {
    heading: "'Open Sans', sans-serif",
    body: "'montserrat', sans-serif",
  },
  colors: {
    primary: tekstediaColorPalatte.zaffre,
    secondary: tekstediaColorPalatte.engineeringOrange,
    tertiary: silver,
    cardColor: lightDark,
    highlight: platinum,
    warning: mangoTango,
    danger: venetianRed,
    darken: black,
  },
  breakpoints,
  components: {
    Button,
    Styles,
    Card,
    Box,
    Grid,
  },
});
