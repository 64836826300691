import IRoute from '../interfaces/route';
import {lazy, Suspense} from "react";
// import  lazy from '@ lazy/component';
import SkeletonScreen from '../components/common/SkeletonScreen/SkeletonScreen';
import { ROUTE } from '.';

const Login =  lazy(() => import('../pages/auth/Login.page'));
const SignUp =  lazy(() => import('../pages/auth/Signup.page'));
const Landing =  lazy(() => import('../pages/Landing.page'));
const SearchResults =  lazy(
  () => import('../pages/SearchResults/SearchResults.page')
);
const ExpandedJob =  lazy(
  () => import('../pages/ExpandedJob/ExpandedJob.page')
);
const ApplicationProc =  lazy(
  () => import('../pages/ApplicationProc/ApplicationProc.page')
);
const ApplicationDone =  lazy(
  () => import('../pages/ApplicationDone/ApplicationDone.page')
);

const Teams =  lazy(() => import('../pages/Teams/Teams.page'));
const Volunteeridm =  lazy(
  () => import('../pages/Volunteerism/Volunteerism.page')
);
const Internships =  lazy(
  () => import('../pages/Internships/Internships.page')
);
const Jobs =  lazy(() => import('../pages/Jobs/Jobs.page'));
const Students =  lazy(
  () => import('../pages/Students/Students.page')
);
const InternshipList =  lazy(
  () =>
    import(
      '../packages/InternshipListing/src/components/InternshipList/InternshipList'
    )
);
const VolunteerList =  lazy(
  () =>
    import(
      '../packages/VolunteerListing/src/components/VolunteerList/VolunteerList'
    )
);
const ForgotPassword =  lazy(
  () => import('../pages/auth/ForgotPassword')
);

const routes: IRoute[] = [
  {
    path: ROUTE.LANDING,
    name: 'Landing',
    element: Landing,
    exact: true,
    selected: true,
  },
  {
    path: ROUTE.TEAMS,
    name: 'Teams',
    element: Teams,
    exact: true,
    selected: true,
  },
  {
    path: ROUTE.VOLUNTEERISM,
    name: 'Volunteerism',
    element: Volunteeridm,
    exact: true,
    selected: true,
  },
  {
    path: ROUTE.INTERNSHIPS,
    name: 'Internships',
    element: Internships,
    exact: true,
    selected: true,
  },
  {
    path: ROUTE.INTERNSHIPS_LISTING,
    name: 'internships-listing',
    element: InternshipList,
    exact: true,
    selected: true,
  },
  {
    path: ROUTE.VOLUNTEER_LISTING,
    name: 'volunteer-listing',
    element: VolunteerList,
    exact: true,
    selected: true,
  },
  {
    path: ROUTE.JOBS,
    name: 'Jobs',
    element: Jobs,
    exact: true,
    selected: true,
  },
  {
    path: ROUTE.STUDENTS,
    name: 'Students',
    element: Students,
    exact: true,
    selected: true,
  },
  {
    path: ROUTE.LOGIN,
    name: 'Login',
    element: Login,
    exact: true,
    selected: false,
  },
  {
    path: ROUTE.SIGNUP,
    name: 'Signup',
    element: SignUp,
    exact: true,
    selected: false,
  },
  {
    path: ROUTE.FORGOT_PASSWORD,
    name: 'ForgotPassword',
    element: ForgotPassword,
    exact: true,
    selected: false,
  },
  {
    path: ROUTE.SEARCH,
    name: 'Search',
    element: SearchResults,
    exact: false,
    selected: false,
  },
  {
    path: ROUTE.EXPANDED_JOB,
    name: 'ExpandedJob',
    element: ExpandedJob,
    exact: false,
    selected: false,
  },
  {
    path: ROUTE.EXPANDED_INTERNSHIP,
    name: 'ExpandedInternship',
    element: ExpandedJob,
    exact: false,
    selected: false,
  },
  {
    path: ROUTE.APPLICATION_PROC,
    name: 'ApplicationProc',
    element: ApplicationProc,
    exact: false,
    selected: false,
  },
  {
    path: ROUTE.APPLICATION_DONE,
    name: 'ApplicationDone',
    element: ApplicationDone,
    exact: false,
    selected: false,
  },
];

export const navRoutes: IRoute[] = [
  {
    path: ROUTE.VOLUNTEERISM,
    name: 'Volunteerism',
    element: Volunteeridm,
    exact: true,
    selected: true,
  },
  {
    path: ROUTE.INTERNSHIPS,
    name: 'Internships',
    element: Internships,
    exact: true,
    selected: true,
  },
  {
    path: ROUTE.JOBS,
    name: 'Jobs',
    element: Jobs,
    exact: true,
    selected: true,
  },
];

export const nestRoutes: IRoute[] = [
  // {
  //   path: USER_DASHBOARD_ROUTE.DEFAULT,
  //   name: "Dashboard",
  //   element: Dashboard,
  //   selected: true,
  //   exact: true,
  //   icon: MdDashboard,
  // },
  // {
  //   path: USER_DASHBOARD_ROUTE.USER_DEPOSIT,
  //   name: "Deposit",
  //   element: Deposit,
  //   selected: false,
  //   exact: true,
  //   icon: RiLuggageDepositFill,
  // },
  // {
  //   path: USER_DASHBOARD_ROUTE.USER_ECOMMERCE,
  //   name: "ECommerce",
  //   element: ECommerce,
  //   selected: false,
  //   exact: true,
  //   icon: SiMarketo,
  // },
  // {
  //   path: USER_DASHBOARD_ROUTE.USER_Withdraw,
  //   name: "Withdraw",
  //   element: Withdraw,
  //   selected: false,
  //   exact: true,
  //   icon: FaHandHoldingUsd,
  // },
  // {
  //   path: USER_DASHBOARD_ROUTE.USER_GENEALOGY,
  //   name: "Genealogy",
  //   element: Genealogy,
  //   selected: false,
  //   exact: true,
  //   icon: TiFlowChildren,
  // },
  // {
  //   path: USER_DASHBOARD_ROUTE.USER_PAYOUT,
  //   name: "Payout",
  //   element: Payout,
  //   selected: false,
  //   exact: true,
  //   icon: MdPayments,
  // },
  // {
  //   path: USER_DASHBOARD_ROUTE.USER_SUPPORT,
  //   name: "Support",
  //   element: Support,
  //   selected: false,
  //   exact: true,
  //   icon: MdSupportAgent,
  // },
];

export const inDashboardRoutes: IRoute[] = [
  //   {
  //     path: IN_DASHBOARD_ROUTE.DEFAULT,
  //     name: "Portfolio",
  //     element: Portfolio,
  //     exact: true,
  //     selected: true,
  //   },
  //   {
  //     path: IN_DASHBOARD_ROUTE.USER_ACTIVITY,
  //     name: "Activity",
  //     element: Activity,
  //     exact: true,
  //     selected: false,
  //   },
  //   {
  //     path: IN_DASHBOARD_ROUTE.USER_EXTERNAL,
  //     name: "External",
  //     exact: true,
  //     element: External,
  //     selected: false,
  //   },
  //   {
  //     path: IN_DASHBOARD_ROUTE.USER_NEWS,
  //     name: "News",
  //     exact: true,
  //     element: News,
  //     selected: false,
  //   },
];

export default routes;
