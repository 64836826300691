import { Flex, Text,useColorMode } from '@chakra-ui/react';
import React from 'react';
import { Link as RouteLink, useLocation } from 'react-router-dom';
import AppName from '../../components/common/AppName';
import Box from '../../components/common/Box';
import { navRoutes } from '../../routes/routes';

type ItemType = {
  text: string;
  route: string;
};

const Item = ({ text, route }: ItemType) => {
  var active = false;
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const RouteCapitalise = splitLocation[1].toUpperCase();
  if (RouteCapitalise === text.toUpperCase()) {
    active = true;
  }

  return (
    <Flex
      borderBottom={active ? `1px solid #0A1DAF` : `inherit`}
      alignItems="center"
      
      borderRadius={0}
      _hover={{ cursor: 'pointer' }}
      as={RouteLink}
      to={route}
    >
      <Text>{text}</Text>
    </Flex>
  );
};
const NavLong = () => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      fontSize="14px"
      fontWeight={500}

      ml="3.5rem"
      gap="1.5rem"
      height="4.6vh"
      
    >
      {navRoutes.map((deRoute, index) => {
        return (
          <Item
            key={index}
            text={deRoute.name}
            route={deRoute.path}
          />
        );
      })}
    </Flex>
  );
};

export default NavLong;
