import { JobDataType } from "../utils/types";
import jobData from "../Data/JOB_DATA.json"
import { getJobs } from "../utils/api";

export async function  makeExtendedJobData():  Promise<JobDataType[]> {
    const extendedJobData = (await getJobs()).map((job) => {
        const generatedId =
            'job' +
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15);
        const clicked = true;
        return {
            ...job,
            id: generatedId,
            clicked,
        };
    });
    return extendedJobData;
}