import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  useAnimation,
  AnimatePresence,
  motion,
  useScroll,
} from "framer-motion";
import { Image, Flex, Text } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";
import Box from "./Box";
import Logo from "../../assets/images/TeksTedialogo.png";

const AppNameVariants = {
  visible: { opacity: 1, scale: 4, transition: { duration: 1 } },
  hidden: { opacity: 0, scale: 0 },
};

function AppName({ txtDecoration }: any) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      animate={{ scale: 1.1 }}
      initial="visible"
      variants={AppNameVariants}
    >
      <Link to="/">
        <Flex className="animate__backInDown" textDecoration={txtDecoration}>
          <Image w="auto" h={6} src={Logo} />
          <Text fontWeight={400} fontSize="14px" color="#5f6368">
            Apply
          </Text>
        </Flex>
      </Link>
    </motion.div>
  );
}

export default AppName;
